import styled from 'styled-components';

import styles from '../../src/styles';

const Background = styled.div`
  background-image: url('/static/img/mv-logo-dimmed.png'), ${styles.gradients.background};
  min-height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  width: 100%;
`;

export default Background;

import React from 'react';
import BridgeView from '../views/BridgeView';


class Index extends React.Component {
  render() {
    return (
      <BridgeView />
    );
  }
}

export default Index;

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

const defaultProps = {
  className: '',
};

const Img = ({ className, src, alt }) => (
  <img className={className} src={`/static/img/${src}`} alt={alt} />
);

Img.propTypes = propTypes;
Img.defaultProps = defaultProps;

export default Img;

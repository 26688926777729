import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import styles, { media } from '../src/styles';

import Img from './common/Img';
import ActiveLink from './common/ActiveLink';
import SVG from './common/SVG';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'header',
};

class HeaderBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.setShowMenu = this.setShowMenu.bind(this);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  setShowMenu() {
    const { width } = this.state;
    const showMenu = width >= 600;

    this.setState({
      showMenu,
    });
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
    this.setShowMenu();
  }

  toggleMenu() {
    const { showMenu } = this.state;
    this.setState({
      showMenu: !showMenu,
    });
  }

  render() {
    const { showMenu } = this.state;
    const { className } = this.props;

    return (
      <header className={className}>
        <Shadow />
        <LogoWrap>
          <StyledLink href="/">
            <MainLogo src="mv-logo-white.png" alt="Tokes Logo" />
          </StyledLink>
          <Title href="/">
            <TitleTop>TOKES</TitleTop>
            <TitleBottom>EXPLORER</TitleBottom>
          </Title>
          <StyledMenuIcon onClick={this.toggleMenu} open={showMenu} />
        </LogoWrap>
        {showMenu && (
          <Fragment>
            <ActiveLink href="/">
              <HomeSVG src="home" size="20" />
              HOME
            </ActiveLink>
            <ActiveLink href="/blocks">BLOCKS</ActiveLink>
            <ActiveLink href="/wallets">ADDRESSES</ActiveLink>
          </Fragment>
        )}
      </header>
    );
  }
}

const Header = styled(HeaderBase)`
  color: ${styles.colors.white};
  font-family: ${styles.fonts.gothamBlack};
  display: flex;
  align-items: center;
  padding: 16px;
  flex-wrap: wrap;
  ${media.phone`
    padding: 12px 12px 0 12px;
  `};
  ${media.tabletPortrait`
    justify-content: center;
    padding-top: 24px;
  `};
  ${media.tabletLandscape`
    margin-bottom: 24px;
  `};
`;

HeaderBase.propTypes = propTypes;
HeaderBase.defaultProps = defaultProps;

const LogoWrap = styled.span`
  display: flex;
  position: relative;
  align-items: center;
  ${media.phone`
    width: 100%;
  `};
  ${media.tabletPortrait`
    width: 100%;
    margin-bottom: 24px;
    justify-content: center;
    left: 24px;
  `};
`;

const Title = styled.a`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  margin-right: 46px;
  color: ${styles.colors.white};
  &:visited {
    color: inherit;
  }
`;

const TitleTop = styled.h3`
  font-family: ${styles.fonts.gothamBlack};
  margin: 0;
  padding: 0;
  color: ${styles.colors.white};
`;

const TitleBottom = styled.h3`
  font-family: ${styles.fonts.gotham};
  margin: 0;
  padding: 0;
  font-weight: 200;
  color: ${styles.colors.white};
`;

const MainLogo = styled(Img)`
  height: 28px;
  width: auto;
  margin-right: 13px;

  fill: #ffffff;
`;

const StyledLink = styled.a`
  color: ${styles.colors.white30};
  text-decoration: none;
  font-family: ${styles.fonts.gotham};
  margin-right: 28px;
  &:visisted {
    color: initial;
  }
  &:first-of-type {
    margin: 0;
  }
  ${media.phone`
    margin-top: 12px;
  `};
  ${media.tabletPortrait`
    margin-right: 12px;
  `};
`;

const HomeSVG = SVG.extend`
  margin: 0 8px;
  ${media.phone`
    margin: 0 8px 0 0;
  `};
`;

const Shadow = styled.div`
  background: ${styles.gradients.header};
  top: 0;
  left: 0;
  right: 0;
  height: 57px;
  width: 100%;
  position: absolute;
  z-index: 100;
  pointer-events: none;
`;

const menuIconPropTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const menuIconDefaultProps = {
  className: '',
  open: true,
};

const MenuIcon = ({ className, open, onClick }) => (open ? (
  <button className={className} onClick={onClick} type="button">
    <SVG src="menu-open" size="32" />
  </button>
) : (
  <button className={className} onClick={onClick} type="button">
    <SVG src="menu" size="32" />
  </button>
));

MenuIcon.propTypes = menuIconPropTypes;
MenuIcon.defaultProps = menuIconDefaultProps;

const StyledMenuIcon = styled(MenuIcon)`
  margin-left: auto;
  border: 0;
  background: none;
  div,
  svg,
  path {
    fill: ${styles.colors.white};
  }
  ${media.tabletPortrait`
    display: none;
  `}
  ${media.tabletLandscape`
    display: none;
  `}
  ${media.desktop`
    display: none;
  `};
`;

export { Header as default, MenuIcon, HeaderBase };

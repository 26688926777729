import React from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import styles, { media } from '../../src/styles';

const checkIfActive = (pathname, href) => {
  switch (true) {
    case pathname === href:
      return true;
    case pathname === '/block' && href === '/blocks':
      return true;
    case pathname === '/address' && href === '/wallets':
      return true;
    default:
      return false;
  }
};

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  router: PropTypes.object,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  children: null,
  router: {},
  className: '',
};

const ActiveLink = ({
  className, children, router, href,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    router.push(href);
  };

  return (
    <Link
      active={checkIfActive(router.pathname, href)}
      router={router}
      className={className}
      href={href}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

ActiveLink.propTypes = propTypes;
ActiveLink.defaultProps = defaultProps;

const Link = styled.a`
  font-family: ${props => (props.active ? `${styles.fonts.gothamBlack}` : `${styles.fonts.gotham}`)};
  color: ${props => (props.active ? styles.colors.white : styles.colors.white30)};
  margin-right: 33px;
  text-decoration: none;
  display: flex;
  align-items: center;
  path,
  svg {
    fill: ${props => (props.active ? styles.colors.white : styles.colors.white30)};
  }
  ${media.phone`
    width: 100%;
    margin-right: 0;
    margin-top: 12px;
  `};
  ${media.tabletPortrait`
    margin-right: 16px;
    width: unset;
  `};
`;

export default withRouter(ActiveLink);

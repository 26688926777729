import { css } from 'styled-components';

const styles = {
  colors: {
    black: '#20273F',
    danger: '#FF7D7A',
    grays: {
      light: '#F2F3F5',
      medium: '#D6D8DE',
      dark: '#999999',
    },
    info: '#12ABE6',
    lightBlue: '#EFFEFF',
    lightGreen: '#DDFFFD',
    lightPurple: '#F3F2FF',
    primary: '#6A69FD',
    success: '#31C7BF',
    white: '#fff',
    white30: 'rgba(255, 255, 255, 0.3)',
  },

  fonts: {
    gotham: 'gotham',
    gothamBold: 'gotham bold',
    gothamBlack: 'gotham black',
  },

  gradients: {
    background: 'linear-gradient(0deg, #1D2339, #242C48, #1E253B)',
    info: 'linear-gradient(90deg, #00BFC5, #00E7C7)',
    primary: 'linear-gradient(260deg, #AE48F1, #6A69FD)',
    header:
      'linear-gradient(180deg, hsla(176, 60%, 48%, 0.33), hsla(176, 100%, 18%, 0), hsla(0, 0%, 0%, 0))',
  },

  shadows: {
    gray: '0px 2px 8px hsla(0, 0%, 50%, 0.3)',
    info: '0px 2px 8px hsla(196, 85%, 48%, 0.3)',
    primary: '0px 2px 8px hsla(244, 96%, 69%, 0.3)',
    success: '0px 2px 8px hsla(176, 60%, 48%, 0.3)',
  },
};

const getMediaQuery = (args, query) => css`
  @media ${query} {
    ${css(args)};
  }
`;

const media = {
  phone: args => getMediaQuery(args, '(max-width: 599px)'),
  tabletPortrait: args => getMediaQuery(args, '(min-width: 600px) and (max-width: 1199px)'),
  tabletLandscape: args => getMediaQuery(args, '(min-width: 900px) and (max-width: 1199px)'),
  desktop: args => getMediaQuery(args, '(min-width: 1200px)'),
  desktopLarge: args => getMediaQuery(args, '(min-width: 1800px)'),
};

export { styles as default, media };

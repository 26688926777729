import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  verifyAuthData, nodeInteraction, libs,
} from '@waves/waves-transactions';

import Img from '../components/common/Img';
import SVG from '../components/common/SVG';
import styles, { media } from '../src/styles';

import Background from '../components/common/Background';
import Header from '../components/Header';

const BURN_ADDRESS = '3PFFfgjnVty32rz5HE6jvHhFCtNHDhAddUv';
const TOKES_ASSET_ID = 'ATrsNse6UZwNSMruNKsR4bnoTsp89BvLMmBZECRcPoif';
const WAVES_API_ADDRESS = 'https://nodes.wavesnodes.com';
const WAVES_KEEPER_URL = 'https://docs.keeper-wallet.app/browser-extension/quick-start';

const getBridgeTx = (amount, solanaAddress) => ({
  type: 4,
  data: {
    amount: { tokens: amount, assetId: TOKES_ASSET_ID },
    fee: { tokens: '0.001', assetId: 'WAVES' },
    recipient: BURN_ADDRESS,
    attachment: solanaAddress,
  },
});

async function getTokesBalance(address) {
  return nodeInteraction.assetBalance(TOKES_ASSET_ID, address, WAVES_API_ADDRESS);
}

function isValidSolanaAddress(address) {
  const regex = new RegExp('^[1-9A-HJ-NP-Za-km-z]{32,44}$');
  return regex.test(address);
}

export const authValidate = ({
  data, signature, publicKey, chainId,
}) => {
  const chain = typeof chainId === 'string' ? chainId : String.fromCharCode(chainId);
  const address = libs.crypto.address({ publicKey }, chain);
  return verifyAuthData({ publicKey, address, signature }, data);
};

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

class BridgeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      solanaAddressInput: '',
      walletConnected: false,
      wavesKeeper: null,
      wavesAddress: null,
      tokesBalance: 0,
      statusMessage: 'Waiting For Wallet Connection...',
      statusColor: styles.colors.white,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBridge = this.handleBridge.bind(this);
    this.connectWallet = this.connectWallet.bind(this);
  }

  setStateAsync = stateData => new Promise(resolve => this.setState(stateData, resolve));

  /**
   * Keeper functions
   */
  getPublicState = async () => {
    const { wavesKeeper } = this.state;
    return wavesKeeper.publicState();
  }

  async connectWallet() {
    const { walletConnected } = this.state;
    if (walletConnected) return null;

    const { WavesKeeper, KeeperWallet } = window;

    const wavesWallet = KeeperWallet || WavesKeeper;

    if (!wavesWallet) {
      window.open(WAVES_KEEPER_URL, '_blank');
      return this.setStateAsync({
        statusMessage: 'Waves Keeper not found, please install Waves Keeper',
        statusColor: styles.colors.danger,
      });
    }

    let keeperState = null;

    try {
      keeperState = await wavesWallet.publicState();
    } catch (er) {
      await this.setStateAsync({
        statusMessage: 'FAILED: Waves Keeper Authorization has been rejected.',
        statusColor: styles.colors.danger,
      });
    }

    try {
      const approved = await wavesWallet.resourceIsApproved();
      if (!approved) {
        return this.setStateAsync({
          statusMessage: 'FAILED: Waves Keeper Permissions are not authorized, please enable interactions from this domain in your Waves Keeper settings.',
          statusColor: styles.colors.danger,
        });
      }
    } catch (er) {
      return this.setStateAsync({
        statusMessage: er,
        statusColor: styles.colors.danger,
      });
    }

    await this.setStateAsync({
      wavesKeeper: wavesWallet,
    });

    try {
      keeperState = await this.getPublicState();

      const wavesAddress = keeperState.account.address;
      const tokesBalance = await getTokesBalance(wavesAddress);
      const { solanaAddressInput } = this.state;

      return this.setStateAsync({
        walletConnected: true,
        statusMessage: `Waves Address: ${wavesAddress} | TKS Balance: ${(tokesBalance / 10 ** 8) || 0} -> Solana Address: ${solanaAddressInput}`,
        statusColor: styles.colors.success,
        wavesAddress,
        tokesBalance,
      });
    } catch (er) {
      // eslint-disable-next-line no-console
      console.log(er);
    }

    return true;
  }

  handleChange(event) {
    const { tokesBalance, wavesAddress } = this.state;
    this.setState({
      solanaAddressInput: event.target.value,
    });

    if (!isValidSolanaAddress(event.target.value)) {
      this.setState({
        statusMessage: 'FAILED: Invalid Solana Address',
        statusColor: styles.colors.danger,
      });
    } else {
      this.setState({
        statusMessage: `Waves Address: ${wavesAddress} | TKS Balance: ${(tokesBalance / 10 ** 8) || 0} -> Solana Address: ${event.target.value}`,
        statusColor: styles.colors.success,
      });
    }
  }

  async handleBridge() {
    const {
      solanaAddressInput, walletConnected, wavesKeeper, tokesBalance,
    } = this.state;

    if (!walletConnected) {
      this.setState({
        statusMessage: 'FAILED: Wallet not connected',
        statusColor: styles.colors.danger,
      });
      return;
    }

    if (!solanaAddressInput || !isValidSolanaAddress(solanaAddressInput)) {
      this.setState({
        statusMessage: 'FAILED: Invalid Solana Address',
        statusColor: styles.colors.danger,
      });
      return;
    }

    if (tokesBalance === 0) {
      this.setState({
        statusMessage: 'FAILED: Zero Tokes Balance',
        statusColor: styles.colors.danger,
      });
      return;
    }

    try {
      const tksDecimalBalance = (tokesBalance / 10 ** 8) || 0;
      const bridgeTx = getBridgeTx(tksDecimalBalance, solanaAddressInput);
      await wavesKeeper.signAndPublishTransaction(bridgeTx);

      this.setState({
        statusMessage: `Deposited ${tksDecimalBalance} TKS to the One-Way Solana Bridge Successfully!  You will receive your SPL Tokes at ${solanaAddressInput} momentarily.`,
        statusColor: styles.colors.success,
      });
    } catch (er) {
      // eslint-disable-next-line no-console
      console.error(er);

      this.setState({
        statusMessage: 'FAILED: Bridge Transaction Failed',
        statusColor: styles.colors.danger,
      });
    }
  }

  render() {
    const {
      solanaAddressInput, walletConnected, statusColor, statusMessage, wavesAddress,
    } = this.state;
    const { className } = this.props;

    return (
      <Background>
        <Header />
        <ConnectLinkWrap>
          <ConnectLink
            style={{
              background: !walletConnected ? styles.gradients.primary : styles.gradients.info,
            }}
            onClick={() => this.connectWallet()}
          >
            <ConnectIcon src="mv-logo-white.png" alt="Tokes Logo" />
            { !walletConnected ? 'CONNECT WALLET' : `${wavesAddress.substr(0, 6)}...${wavesAddress.substr(-4)}` }
          </ConnectLink>
        </ConnectLinkWrap>
        <InputContentWrapper>
          <WalletAddressInputWrapper>
            <WalletAddressInput>
              <form onSubmit={this.handleBridge} className={className}>
                <ArrowIcon
                  src="arrow-right"
                  fill={`${styles.colors.grays.dark}`}
                  size="34"
                />
                <Input
                  onChange={this.handleChange}
                  type="text"
                  placeholder="Solana Wallet Address"
                  value={solanaAddressInput}
                />
              </form>
            </WalletAddressInput>
          </WalletAddressInputWrapper>
          <BalanceLabel style={{ color: statusColor }}>
            {statusMessage}
          </BalanceLabel>
          <BridgeButtonWrapper>
            <BridgeButton
              disabled={
                !walletConnected
                || !solanaAddressInput.length
                || !isValidSolanaAddress(solanaAddressInput)
              }
              onClick={() => this.handleBridge()}
            >
              BRIDGE
            </BridgeButton>
          </BridgeButtonWrapper>
        </InputContentWrapper>
      </Background>
    );
  }
}

BridgeView.propTypes = propTypes;
BridgeView.defaultProps = defaultProps;

const ConnectLinkWrap = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-left: auto;
  margin-right: 18px;
  ${media.phone`
    margin-left: 0;
    margin-right: 0;
  `};
  ${media.tabletPortrait`
    margin-left: 30px;
    margin-right: 0;
  `};
  ${media.tabletLandscape`
    margin-left: 30px;
    margin-right: 0;
  `};
`;

const ConnectIcon = styled(Img)`
  width: 25px;
  margin-right: 12px;
`;

const ConnectLink = styled.div`
  color: ${styles.colors.white};
  border-radius: 25px;
  background: ${styles.gradients.primary};
  padding: 8px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: ${styles.fonts.gothamBold};
  font-size: 15px;
  cursor: pointer;
  ${media.phone`
    margin: 0;
    transform: scale(0.9);
  `};
  ${media.tabletPortrait`
    margin: 0;
  `};
  ${media.tabletLandscape`
    margin: 0;
  `};
`;

const InputContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 420px;
  flex-direction: column;
`;

const BridgeButton = styled.button`
  color: ${styles.colors.white};
  border-radius: 25px;
  background: ${styles.gradients.info};
  padding: 8px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: ${styles.fonts.gothamBold};
  font-size: 15px;
  cursor: pointer;
  &:disabled {
    background: ${styles.colors.grays.medium};
    cursor: not-allowed;
  }
  margin-right: 42px;
  margin-left: 210px;
`;

const Input = styled.input`
  background: 0;
  border: 0;
  flex-grow: 1;
`;

const WalletAddressInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WalletAddressInput = styled.div`
  border-radius: 100px;
  box-shadow: 0 5px 30px hsla(226, 32%, 18%, 0.09);
  background: ${styles.colors.white};
  width: 523px;
  max-width: 532px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px 31px;
  margin: 0 15% 0 auto;
  top: 33px;
  position: relative;
  margin-top: -35px;
  form {
    display: flex;
  }
  input {
    font-size: 18px;
    font-family: ${styles.fonts.gothamBold};
    color: ${styles.colors.info};
    &::placeholder {
      color: ${styles.colors.grays.dark} !important;
      opacity: 1;
    }
    flex-direction: row;
    display: flex;
  }
  ${media.phone`
    width: 90vw;
    height: 48px;
    margin: 20px auto;
    padding: 0 20px;
    top: 0;
    input {
      font-size: 12px;
    }
  `};
  ${media.tabletPortrait`
    width: 80vw;
    margin: 0 auto;
  `};
`;

const ArrowIcon = styled(SVG)`
  margin-right: 15px;
  ${media.phone`
    margin-right: 0;
    width: 20px;
    div, svg, path {
      width: 20px;
    }
  `};
`;

const BridgeButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
  margin-left: 42px;
`;

const BalanceLabel = styled.p`
  margin: 50px 0 0 0 !important;
  font-family: ${styles.fonts.gothamBold};
  color: ${styles.colors.lightGreen};
  font-size: 18px;
  width: 100%;
  text-align: center;
`;

export default BridgeView;

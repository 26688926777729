import React from 'react';
import styled from 'styled-components';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

const SVGBase = ({ className, src }) => (
  <span className={className}>
    <ReactSVG path={`/static/svg/${src}.svg`} />
  </span>
);

const SVG = styled(SVGBase)`
  width: ${props => `${props.size}px` || '32px'};
  height: ${props => `${props.size}px` || '32px'};
  div,
  svg,
  path {
    width: ${props => `${props.size}px` || '32px'};
    height: ${props => `${props.size}px` || '32px'};
    fill: ${props => props.fill};
  }
`;

SVGBase.propTypes = propTypes;

export default SVG;
